import styles from "./sidenav.module.css"
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DarkMode from "@mui/icons-material/DarkMode";
import LightMode from "@mui/icons-material/LightMode";
import { navData } from "../lib/navData";
import { useState, useEffect } from "react";


export default function Sidenav() {
    const [open, setopen] = useState(false)
    const [dark, setdark] = useState(() => {
        if (localStorage.getItem('theme') === 'dark') {
            return false
        } else { 
            return true 
        }
    });

    const toggleOpen = () => {
        setopen(!open)
    }
    
    const [theme, setTheme] = useState(
        localStorage.getItem('theme') || 'dark'
        );
    const toggleTheme = () => {
    if (theme === 'light') {
    setTheme('dark');
    setdark(!dark);
    } else {
    setTheme('light');
    setdark(!dark);
    }
    };
    useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    }, [theme]);
    
  return (

    <div className={open?styles.sidenav:styles.sidenavClosed}>
        <button className={styles.menuBtn} onClick={toggleOpen}>
            {open? <KeyboardDoubleArrowLeftIcon />: <KeyboardDoubleArrowRightIcon />}
        </button>
        {navData.map(item =>{
            return <NavLink key={item.id} className={styles.sideitem} to={item.link}>
            {item.icon}
            <span className={styles.linkText}>{item.text}</span>
        </NavLink>
        })}
        <button className={styles.menuModeBtn} onClick={toggleTheme}>
            {dark? <DarkMode />: <LightMode />}
        </button>   
    </div>
  )
}
